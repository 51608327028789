import React from "react"
import { MediaAssets } from "../../../../templates/media-library.template"
import { AppFunctionComponent, QueryResultFlat } from "../../../../types"
import MediaLibraryFilters from "./media-library-filters.component"

type Props = {
  filters: QueryResultFlat<Partial<MediaAssets>>
}

const MediaLibraryContent: AppFunctionComponent<Props> = ({
  filters,
  children,
}) => (
  <>
    <MediaLibraryFilters filters={filters} />
    {children}
  </>
)

export default MediaLibraryContent
