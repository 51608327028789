import React from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../types"

const Svg = styled.svg`
  width: 57px;
  height: 57px;
`
type Props = {
  className?: string
}

const ArrowComponent: AppFunctionComponent<Props> = ({ className }) => (
  <Svg viewBox="0 0 57 57" className={className}>
    <g
      className="circle"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(-124.000000, -200.000000)" fillRule="nonzero">
        <g transform="translate(125.000000, 201.000000)">
          <circle
            stroke="#5C5C5C"
            opacity="0.150613839"
            cx="27.5"
            cy="27.5"
            r="27.5"
          />
          <polygon
            className="arrow"
            fill="#000000"
            opacity="0.8"
            transform="translate(26.941406, 27.332031) scale(-1, 1) translate(-26.941406, -27.332031) "
            points="35.5566406 27.3320312 30.9160156 31.9726562 30.9160156 28.1269531 18.3261719 28.1269531 18.3261719 26.5371094 30.9160156 26.5371094 30.9160156 22.6914062"
          />
        </g>
      </g>
    </g>
  </Svg>
)

export default ArrowComponent
