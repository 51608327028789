import styled from "styled-components"
import { NeutralTextLink } from "../../../ui/components/link/link.component"
import { activeFilter } from "../../about/media/media-library/media-library-filters.styled"
import { smallBodyTextStyles } from "../general-styles.component"

export const DropdownWrapper = styled.div`
  position: relative;
`

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  box-sizing: border-box;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid ${({ theme }) => theme.color.text};
`

export const ArrowContainer = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  ${ArrowDown} {
    transition: transform 0.5s;
    transform: rotate(${({ active }) => (active ? "180deg" : "0deg")});
  }
`

export const Options = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 2;
  border-radius: ${({ theme }) => theme.size.borderRadiusSmaller};
  background-color: ${({ theme }) => theme.color.textInverted};
  box-shadow: 0 0 30px 0 ${({ theme }) => theme.color.commerceLightShadow};
`

export const Option = styled(NeutralTextLink)`
  font-size: ${13 / 16}rem;
  display: flex;
  padding: 10px 15px;
  color: ${({ theme }) => theme.color.text};
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.commerceBorder};
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.background};
    color: inherit;
  }
  &.active {
    ${activeFilter}
  }
`

export const CurrentOption = styled.div`
  ${smallBodyTextStyles}
  font-size: ${13 / 16}rem;
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.size.borderRadiusSmaller};
  display: flex;
  justify-content: center;
  padding: 5px 15px;
  margin: 10px 0;
  margin-right: 60px;
  color: ${({ theme }) => theme.color.text};
  &:hover {
    ${ArrowDown} {
      border-top: 3px solid ${({ theme }) => theme.color.textInverted};
    }
  }
  &.active,
  &:hover {
    ${activeFilter}
  }
`
