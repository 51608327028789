import React from "react"
import useTranslationHook from "../../../../hooks/use-translation.hook"
import { Path } from "../../../../navigation/constants/routes"
import { MediaAssets } from "../../../../templates/media-library.template"
import { QueryResultFlat } from "../../../../types"
import Dropdown from "../../../common/dropdown/dropdown.component"
import { QuaternaryHeading } from "../../../common/heading.component"
import { FilterWrapper, Filter } from "./media-library-filters.styled"

type Props = {
  filters: QueryResultFlat<Partial<MediaAssets>>
}

const MediaLibraryFilters = ({ filters }: Props) => {
  const { nodes } = filters
  const { translate } = useTranslationHook()

  return (
    <>
      <QuaternaryHeading renderAs="h4">
        {translate("media-go-to-section:")}
      </QuaternaryHeading>
      <FilterWrapper>
        {nodes.map(({ slug, displayName, main, subcategory }, index) => {
          return subcategory && subcategory.length ? (
            <Dropdown
              displayName={displayName!}
              slug={slug!}
              options={subcategory}
            />
          ) : (
            main && (
              <Filter
                key={index}
                to={`${Path.MediaLibrary}${slug}`}
                activeClassName={"active"}
              >
                {displayName}
              </Filter>
            )
          )
        })}
      </FilterWrapper>
    </>
  )
}

export default MediaLibraryFilters
