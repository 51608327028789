import React from "react"
import useFallbackImage from "../../../../../hooks/use-fallback-image.hook"
import { AppFunctionComponent } from "../../../../../types"
import { SenaryHeading } from "../../../../common/heading.component"
import {
  InfoWrapper,
  Info,
  Description,
  MediaAssetImage,
  SingleMediaWrapper,
} from "./single-media-asset.styled"
import { MediaItem } from "./single-media-asset.types"

const formatBytes = (a: number, b = 0, k = 1024) => {
  const d = Math.floor(Math.log(a) / Math.log(k))
  return 0 == a
    ? "0 Bytes"
    : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) +
        " " +
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
}

const fixAssetFormat = (format: string) => {
  const substring = format.substring(format.indexOf("/") + 1)
  return substring.trim()
}

const SingleMediaAsset: AppFunctionComponent<MediaItem> = ({
  id,
  displayName,
  media: { gatsbyImageData, file },
}) => {
  const fallbackImage = useFallbackImage()
  const {
    images: { fallback },
  } = gatsbyImageData

  return (
    <SingleMediaWrapper key={id} href={fallback?.src} download>
      <MediaAssetImage image={gatsbyImageData || fallbackImage} />
      <Description>
        <SenaryHeading renderAs="h6">{displayName}</SenaryHeading>
        <InfoWrapper>
          <Info>{fixAssetFormat(file.contentType)}</Info>
          <Info>{formatBytes(file.details.size)}</Info>
        </InfoWrapper>
      </Description>
    </SingleMediaWrapper>
  )
}

export default SingleMediaAsset
