import styled from "styled-components"
import { NeutralBoldRawLink } from "../../../../../ui/components/link/link.component"
import { SenaryHeading } from "../../../../common/heading.component"
import { Image } from "../../../../common/image.component"

export const SingleMediaWrapper = styled(NeutralBoldRawLink)`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.size.borderRadiusSmaller};
  background-color: ${({ theme }) => theme.color.backgroundSuperLight};
  &:hover {
    box-shadow: 0 0 30px 0 ${({ theme }) => theme.color.commerceLightShadow};
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.textInverted};
  }
`

export const MediaAssetImage = styled(Image)`
  height: 0;
  width: 100%;
  padding-bottom: 80%;

  img {
    object-fit: cover;
  }
`

export const Description = styled.div`
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;

  ${SenaryHeading} {
    margin: 0;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Info = styled.p`
  margin: 0;
  font-size: ${14 / 16}rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.info};
  line-height: ${24 / 16}em;
  font-family: ${({ theme }) => theme.font.display};
`
