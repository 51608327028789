import { AppFunctionComponent } from "../../../types"
import React from "react"
import styled from "styled-components"
import { Path } from "../../constants/routes"
import ArrowComponent from "../../../components/common/arrow-icon.component"
import { NeutralTextLink } from "../../../ui"

const ArrowWithCircle = styled(ArrowComponent)`
  margin-right: 10px;
  width: 32px;
  height: 32px;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    margin-right: 20px;
    width: 56px;
    height: 56px;
  }
`

const BackLinkContainer = styled(NeutralTextLink)`
  display: flex;
  align-items: center;
  &:hover {
    ${ArrowWithCircle} {
      .arrow {
        fill: ${({ theme }) => theme.color.linkHover};
      }
      .circle {
        fill: ${({ theme }) => theme.color.hoverIconBackground};
      }
    }
  }
`

const BackLink: AppFunctionComponent<{ to: Path | string }> = ({
  className,
  children,
  to,
}) => (
  <BackLinkContainer className={className} to={to}>
    <ArrowWithCircle />
    {children}
  </BackLinkContainer>
)

export default BackLink
