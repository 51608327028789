import React, { useState } from "react"
import { AppFunctionComponent } from "../../../types"
import { DropdownOptions } from "./dropdown.interface"
import {
  ArrowContainer,
  ArrowDown,
  CurrentOption,
  DropdownWrapper,
  Options,
  Option,
} from "./dropdown.styled"
import { Path } from "../../../navigation/constants/routes"

const Dropdown: AppFunctionComponent<DropdownOptions> = ({
  options,
  displayName,
  slug,
}) => {
  const [active, setActive] = useState(false)

  const mainOption = {
    displayName: `All ${displayName}`,
    slug,
  }

  return (
    <DropdownWrapper>
      <CurrentOption onClick={() => setActive(!active)}>
        {displayName}
        <ArrowContainer active={active}>
          <ArrowDown />
        </ArrowContainer>
      </CurrentOption>
      {active && (
        <Options>
          {[mainOption, ...options].map((option, index) => (
            <Option
              key={index}
              onClick={() => setActive(false)}
              to={`${Path.MediaLibrary}${option.slug}`}
              activeClassName={"active"}
            >
              {option.displayName}
            </Option>
          ))}
        </Options>
      )}
    </DropdownWrapper>
  )
}

export default Dropdown
