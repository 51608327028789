import styled, { css } from "styled-components"
import { NeutralTextLink } from "../../../../ui/components/link/link.component"

export const activeFilter = css`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.button};
  color: ${({ theme }) => theme.color.textInverted};
`

export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

export const Filter = styled(NeutralTextLink)`
  font-size: ${13 / 16}rem;
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.size.borderRadiusSmaller};
  display: flex;
  justify-content: center;
  padding: 5px 15px;
  margin: 10px 0;
  margin-right: 60px;
  color: ${({ theme }) => theme.color.text};

  &.active,
  &:hover {
    ${activeFilter}
  }
`
