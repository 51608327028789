import React from "react"
import { AppFunctionComponent, TextBuilder } from "../types"
import styled from "styled-components"
import MediaLibraryContent from "../components/about/media/media-library/media-library-content.component"
import SingleMediaAsset from "../components/about/media/media-library/single-media-asset/single-media-asset.component"
import {
  PrimaryHeading,
  SecondaryHeading,
  TertiaryHeading,
} from "../components/common/heading.component"
import Section from "../components/common/sections/section.component"
import Layout from "../components/layout"
import SEO from "../components/seo.component"
import { DetectedBreadcrumbs, TopNavigationWrapper } from "../navigation"
import BackLink from "../navigation/components/back-link/back-link.component"
import { Path } from "../navigation/constants/routes"
import { QueryResultFlat } from "../types"
import { MediaItem } from "../components/about/media/media-library/single-media-asset/single-media-asset.types"
import { graphql } from "gatsby"
import useTranslationHook from "../hooks/use-translation.hook"
import { Menu } from "../navigation/types/menu.interface"

const ItemsWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-auto-rows: auto;
  align-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    grid-template-columns: repeat(4, minmax(260px, 1fr));
  }
`

const Back = styled(BackLink)`
  margin-bottom: 30px;
  margin-top: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    margin-top: 0;
  }
`

const CategoryHeading = styled(SecondaryHeading)`
  margin-top: 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    margin-bottom: 42px;
  }
`

const Separator = styled.hr`
  margin: 30px 0;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.color.commerceBorder};

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    margin: 90px 0;
  }
`

export interface MediaAssets {
  displayName: string
  id: string
  slug: string
  subcategory: MediaAssets[]
  mediaItems: MediaItem[]
  main?: boolean
}
interface Props {
  pageContext: {
    mediaAssets: MediaAssets[]
  }
  data: {
    filters: QueryResultFlat<Partial<MediaAssets>>
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }
}

const MediaLibraryIndex: AppFunctionComponent<Props> = ({
  pageContext,
  data,
}) => {
  const { mediaAssets } = pageContext
  const {
    filters,
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  } = data
  const { translate } = useTranslationHook()

  return (
    <Layout
      topNavigationItems={topNavigationItems}
      footerNavigationItems={footerNavigationItems}
      promobarContent={promobarContent}
      masterPage
      withBackToTop
    >
      <SEO title="Media Library" />
      <TopNavigationWrapper breadcrumbsMenu={<DetectedBreadcrumbs />} />
      <Section>
        <Back to={Path.Media}>{translate("media-back-to")}</Back>
        <PrimaryHeading renderAs="h1">
          {translate("media-mudita-media")}
        </PrimaryHeading>
        <MediaLibraryContent filters={filters}>
          {mediaAssets.map((asset) => (
            <div key={asset.id}>
              <div>
                <CategoryHeading renderAs="h2">
                  {asset.displayName}
                </CategoryHeading>
                <ItemsWrapper>
                  {asset.mediaItems &&
                    asset.mediaItems.map((mediaItem) => (
                      <SingleMediaAsset {...mediaItem} key={asset.id} />
                    ))}
                </ItemsWrapper>
                {!asset.subcategory && <Separator />}
              </div>
              {asset.subcategory &&
                asset.subcategory.map((subcategory, index) => (
                  <div key={index}>
                    <TertiaryHeading renderAs="h3">
                      {subcategory.displayName}
                    </TertiaryHeading>
                    <ItemsWrapper>
                      {subcategory.mediaItems.map((mediaItem) => (
                        <SingleMediaAsset {...mediaItem} key={subcategory.id} />
                      ))}
                    </ItemsWrapper>
                    <Separator />
                  </div>
                ))}
            </div>
          ))}
        </MediaLibraryContent>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query MediaPage($language: String!, $locale: String!) {
    filters: allContentfulMediaLibraryCategory(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        displayName
        slug
        main
        subcategory {
          displayName
          slug
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "67Y5XVRqB0r7iJbO4N8zxx" }
      node_locale: { eq: $locale }
    ) {
      text {
        raw
      }
      node_locale
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default MediaLibraryIndex
